import { h } from 'preact';
// eslint-disable-next-line react/no-deprecated
import { render } from 'react';

import ConversionStep from '../auto/components/ConversionStep';
import ConversionFormNoResultApp from '../auto/components/ConversionFormNoResult/ConversionFormApp';
import ConversionFormModal from '../auto/components/ConversionFormModal';
import NewOffersList from '../auto/components/Listings/Offers/News/Psa';
import NewOffersCard from '../auto/components/Cards/NewsOffersRam';
import ResultSearchSimpleApp from '../auto/components/SearchFilter/ResultSearchSimpleApp';
import SearchFilterUsedmodel from '../auto/components/SearchFilterUsedmodel';
import SimpleFilterNewVehicle from '../auto/components/NewVehicle/SimpleFilter';
import SimpleFilterOffers from '../auto/components/Offers/SimpleFilter';
import SimpleFilterButtonsApp from '../auto/components/SearchFilter/SimpleFilterButtonsApp';
import SimpleFilterButtonsPart from '../auto/components/Parts/SimpleFilterButtons';
import SimpleFilterButtonsUsedModel from '../auto/components/SearchFilterUsedmodel/SimpleFilterButtons';
import StaticConversionFormApp from '../auto/components/StaticConversionFormApp';
import UsedVehicleConversionFormApp from '../auto/components/UsedVehicleConversionFormApp';
import WhatsAppFormModal from '../auto/components/WhatsAppFormModal';
import WhatsAppBoxWithModal from '../auto/components/WhatsAppBoxWithModal';
import ShowcaseChoiceVersion from '../auto/components/ShowcaseChoiceVersion/ShowcaseChoiceVersion';
import NewVehicleConversionFormApp from '../auto/components/NewVehicleConversionFormApp';
import UsedVehiclesList from '../auto/components/Listings/UsedVehicles/Psa';
import DetailTabs from '../auto/components/DetailTabs';
import AccordionVehicle from '../auto/components/AccordionVehicle';
import ChronometerBox from '../auto/components/ChronometerBox';
import SeeConditions from '../auto/components/SeeConditions';
import TimeMissingCard from '../auto/components/TimeMissingCard';
import UsedVehiclesCard from '../auto/components/Cards/Psa';
import ScheduleServiceConversionFormApp from '../auto/components/ScheduleServiceConversionFormApp';
import ScheduleServiceFormModal from '../auto/components/ScheduleServiceFormModal';
import SearchFilterPart from '../auto/components/Parts/SimpleFilter';
import ListAppParts from '../auto/components/Parts/ListApp';
import PartConversionFormApp from '../auto/components/PartConversionFormApp';
import SearchFilterAccessory from '../auto/components/Accessories/SimpleFilter';
import SimpleFilterButtonsAccessory from '../auto/components/Accessories/SimpleFilterButtons';
import ListAppAccessories from '../auto/components/Accessories/ListApp';
import SimpleFilterDirectSales from '../auto/components/DirectSales/SimpleFilter';
import SimpleFilterDirectSalesCategories from '../auto/components/DirectSalesCategories/SimpleFilter';
import ListAppDirectSales from '../auto/components/DirectSales/ListApp';
import ListDirectSalesCategories from '../auto/components/DirectSalesCategories/ListApp';
import SimpleFilterConsortia from '../auto/components/Consortia/SimpleFilter';
import ListAppConsortia from '../auto/components/Consortia/ListApp';
import ConsortiumConversionFormApp from '../auto/components/ConsortiumConversionFormApp';
import PlanMonthsChooser from '../auto/components/Consortium/PlanMonthsChooser';
import VersionComparison from '../auto/components/VersionComparison/VersionComparison';
import DirectSalesFormModal from '../auto/components/DirectSalesFormModal';
import OfferDDMListApp from '../auto/components/Listings/OffersDDM/ListApp';
import OfferDDMFilter from '../auto/components/Listings/OffersDDM/Filter/SimpleFilter';
import OfferDDMResultSearchSimple from '../auto/components/Listings/OffersDDM/Filter/Result/ResultSearchSimple';
import OfferDDMConversionFormNoResult from '../auto/components/Listings/OffersDDM/Filter/Result/ConversionFormNoResult';
import CustomFormApp from '../auto/components/Forms/CustomForm/CustomFormApp';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  require('preact/debug');
}

(function () {
  window.h = h;
  window.render = render;

  /**
   * Para ser usado quando se deseja sobrescrever conteúdo do container de rendeziração.
   * Seu uso é semelhante a função render
   */
  window.hydrate = (vnode, parent) =>
    render(vnode, parent, parent.firstElementChild);

  // Adicione aqui chaves:valor com os componentes que serão atribuídos ao escopo
  // global. Não passe eles pelo hyperscript (h) aqui pois a página que os usa
  // é quem deve compilá-lo com os pros.
  const components = {
    ConversionStep,
    ConversionFormNoResultApp,
    ConversionFormModal,
    NewOffersList,
    ResultSearchSimpleApp,
    SearchFilterUsedmodel,
    SimpleFilterNewVehicle,
    SimpleFilterOffers,
    SimpleFilterButtonsApp,
    SimpleFilterButtonsPart,
    SimpleFilterButtonsUsedModel,
    StaticConversionFormApp,
    UsedVehicleConversionFormApp,
    UsedVehiclesList,
    WhatsAppFormModal,
    WhatsAppBoxWithModal,
    ShowcaseChoiceVersion,
    NewVehicleConversionFormApp,
    DetailTabs,
    TimeMissingCard,
    AccordionVehicle,
    ChronometerBox,
    SeeConditions,
    UsedVehiclesCard,
    NewOffersCard,
    ScheduleServiceConversionFormApp,
    ScheduleServiceFormModal,
    SearchFilterPart,
    ListAppParts,
    PartConversionFormApp,
    SearchFilterAccessory,
    SimpleFilterButtonsAccessory,
    ListAppAccessories,
    SimpleFilterDirectSales,
    SimpleFilterDirectSalesCategories,
    ListAppDirectSales,
    ListDirectSalesCategories,
    SimpleFilterConsortia,
    ListAppConsortia,
    ConsortiumConversionFormApp,
    PlanMonthsChooser,
    VersionComparison,
    DirectSalesFormModal,
    OfferDDMListApp,
    OfferDDMFilter,
    OfferDDMResultSearchSimple,
    OfferDDMConversionFormNoResult,
    CustomFormApp,
  };

  Object.keys(components).forEach(c => (window[c] = components[c]));
})();
